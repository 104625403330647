import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >

<path d="M6007 10733 c-14 -14 -6 -23 32 -33 21 -5 42 -18 46 -28 4 -9 10
-125 14 -257 7 -255 10 -269 62 -324 45 -48 104 -65 224 -66 100 0 115 3 165
27 108 54 130 120 130 389 0 231 11 269 79 269 12 0 21 7 21 15 0 13 -23 15
-145 15 -122 0 -145 -2 -145 -15 0 -8 9 -15 20 -15 10 0 30 -5 43 -11 48 -22
52 -46 52 -279 l0 -216 -27 -42 c-38 -57 -78 -76 -163 -76 -82 0 -124 19 -158
74 -21 33 -22 49 -25 260 -3 255 0 269 61 284 20 5 37 15 37 23 0 10 -34 13
-158 13 -87 0 -162 -3 -165 -7z"/>
<path d="M6945 10732 c-11 -11 -4 -22 24 -36 49 -26 59 -73 92 -405 16 -168
33 -313 38 -320 9 -17 54 -32 68 -22 5 3 65 87 133 186 68 99 130 186 136 192
7 7 14 18 17 25 10 30 17 -8 28 -163 25 -360 21 -331 52 -342 15 -5 34 -6 42
-3 8 3 41 45 73 93 82 122 248 366 259 378 4 6 19 27 33 47 26 39 57 58 94 58
13 0 29 6 36 14 9 12 4 17 -32 27 -68 18 -198 49 -209 49 -20 0 -7 -28 16 -34
34 -9 48 -37 34 -73 -17 -45 -243 -383 -250 -375 -4 4 -11 109 -15 234 -8 252
-6 258 52 258 22 0 34 5 34 14 0 9 -46 25 -145 50 -79 20 -149 36 -155 36 -20
0 -10 -22 25 -54 49 -46 46 -73 -17 -165 -29 -42 -81 -119 -115 -171 -35 -52
-68 -96 -72 -98 -9 -3 -12 25 -36 297 -9 106 -10 171 -4 182 6 12 24 19 52 21
23 2 42 7 42 13 0 10 -321 96 -330 87z"/>
<path d="M5622 10672 c-145 -36 -173 -54 -94 -60 31 -2 46 -9 56 -25 17 -27
68 -225 61 -237 -3 -4 -22 -11 -42 -15 -21 -3 -91 -20 -156 -36 -65 -16 -122
-29 -127 -29 -11 0 -25 45 -47 153 -19 97 -16 112 30 135 30 15 38 46 10 38
-10 -2 -49 -12 -88 -21 -153 -35 -195 -48 -195 -61 0 -9 12 -14 35 -14 26 0
39 -6 51 -26 20 -30 134 -490 134 -540 0 -29 -5 -37 -35 -51 -34 -16 -48 -43
-23 -43 7 0 79 17 160 37 94 24 148 42 148 50 0 9 -15 13 -45 13 -36 0 -48 5
-60 24 -16 24 -65 204 -65 238 0 13 34 24 163 56 89 22 165 37 168 34 13 -14
60 -237 54 -259 -3 -14 -22 -31 -46 -44 -28 -14 -38 -24 -31 -31 14 -14 322
62 322 79 0 8 -14 13 -38 13 -24 0 -45 7 -55 18 -18 20 -137 493 -137 546 0
29 5 38 30 51 30 15 42 46 18 44 -7 -1 -77 -17 -156 -37z"/>
<path d="M4748 10379 c-3 -19 -10 -22 -68 -24 -97 -3 -172 -44 -224 -120 -20
-30 -26 -50 -26 -94 0 -76 35 -132 104 -166 46 -23 61 -25 197 -25 81 0 159
-4 173 -10 28 -10 56 -57 56 -93 0 -65 -109 -147 -196 -147 -56 0 -124 30
-158 70 -21 25 -30 30 -39 21 -9 -9 -9 -16 1 -29 6 -9 30 -52 52 -94 43 -83
60 -97 60 -48 l0 30 64 0 c112 0 209 51 271 143 56 82 46 175 -24 244 -45 43
-95 53 -270 53 -82 0 -157 3 -166 6 -19 8 -45 55 -45 84 0 35 30 76 72 100 59
33 138 25 200 -19 26 -18 51 -30 57 -27 11 7 -67 165 -81 166 -4 0 -8 -10 -10
-21z"/>
<path d="M8234 10077 c-105 -89 -209 -177 -232 -195 -22 -18 -53 -44 -69 -58
-28 -24 -67 -35 -112 -31 -47 4 -31 -21 36 -56 37 -19 82 -42 98 -51 56 -29
74 -16 30 22 -14 12 -25 29 -25 38 0 10 24 41 54 71 l54 54 44 -23 c23 -13 45
-25 48 -28 3 -3 35 -21 72 -40 64 -33 68 -37 68 -70 -1 -54 -17 -113 -35 -127
-12 -8 -25 -9 -42 -2 -62 23 -59 -9 5 -43 26 -14 89 -49 141 -77 52 -28 98
-51 103 -51 16 0 7 24 -19 53 -25 25 -28 35 -27 95 0 37 11 159 23 272 44 398
43 373 18 392 -12 10 -26 17 -32 17 -5 0 -96 -73 -201 -162z m116 -9 c-1 -39
-35 -256 -42 -263 -6 -6 -174 80 -183 95 -3 5 21 30 52 57 32 26 60 50 63 53
20 21 95 80 102 80 4 0 8 -10 8 -22z"/>
<path d="M3869 9922 c-30 -26 -74 -63 -97 -82 -59 -48 -57 -77 2 -46 47 24 69
10 176 -114 30 -35 60 -68 65 -74 59 -65 127 -148 123 -151 -2 -3 -130 27
-284 66 -153 40 -294 75 -312 79 -35 9 -64 -10 -177 -112 -56 -51 -55 -68 3
-49 51 17 74 5 143 -74 28 -33 75 -87 104 -120 158 -181 185 -217 185 -251 0
-9 -23 -39 -51 -65 -43 -43 -56 -49 -91 -49 -32 0 -475 106 -654 156 -15 4
-27 -2 -42 -21 l-21 -26 63 -102 c35 -56 116 -187 180 -292 l118 -190 -5 -57
c-4 -42 -2 -58 7 -58 12 0 131 155 143 186 8 21 -21 17 -42 -6 -10 -11 -24
-20 -31 -20 -21 0 -68 45 -91 89 l-22 41 47 63 c26 34 60 82 76 105 33 48 40
48 136 13 40 -15 45 -20 44 -46 -1 -17 -5 -40 -9 -52 -18 -55 26 -14 119 112
78 104 121 151 198 216 54 45 98 86 98 91 0 6 -12 7 -27 4 -89 -22 -82 -26
-203 114 -30 35 -69 79 -86 99 -95 109 -124 146 -116 149 5 1 38 -5 73 -13 35
-9 82 -21 104 -26 22 -5 144 -35 270 -68 149 -38 235 -55 245 -50 8 5 30 22
49 38 l34 30 -79 93 c-43 51 -81 95 -84 98 -3 3 -46 52 -96 110 -173 202 -174
204 -132 260 39 53 8 54 -53 2z m-649 -1073 c64 -17 122 -34 130 -39 10 -6 -2
-29 -50 -94 -47 -63 -67 -83 -76 -75 -7 5 -32 42 -55 82 -24 40 -56 91 -71
115 -36 52 -34 52 122 11z"/>
<path d="M6270 9674 c-542 -35 -1078 -199 -1505 -461 -114 -69 -258 -166 -308
-207 -321 -257 -541 -493 -745 -798 -51 -75 -92 -139 -92 -142 0 -4 -11 -24
-24 -45 -105 -166 -250 -516 -320 -771 -94 -345 -138 -796 -106 -1098 30 -286
42 -361 87 -542 56 -229 119 -404 224 -628 21 -45 39 -84 39 -86 0 -2 32 -59
71 -127 97 -167 245 -383 303 -441 7 -7 17 -20 22 -29 9 -17 140 -161 211
-234 22 -22 78 -74 124 -115 46 -41 86 -77 89 -80 43 -43 283 -216 405 -290
70 -43 316 -170 390 -202 446 -189 830 -268 1305 -268 231 0 287 4 495 31 376
50 798 198 1175 414 343 196 708 519 950 840 25 33 47 62 50 65 4 3 29 41 57
85 29 44 56 85 60 90 22 25 136 234 191 347 123 256 234 609 272 863 6 39 15
97 20 130 6 33 15 143 20 244 19 327 -14 666 -92 966 -17 66 -33 132 -36 148
-2 15 -8 30 -13 33 -5 3 -9 12 -9 20 0 19 -92 265 -132 354 -53 117 -165 330
-179 340 -3 3 -13 19 -22 37 -71 138 -311 437 -498 619 -165 161 -295 267
-483 394 -89 59 -243 150 -256 150 -5 0 -10 3 -12 8 -3 7 -210 112 -222 112
-4 0 -31 11 -59 24 -29 13 -86 36 -127 51 -41 15 -86 31 -100 36 -25 9 -91 28
-240 67 -215 58 -468 91 -730 96 -107 2 -220 2 -250 0z m535 -280 c72 -8 148
-18 170 -23 22 -5 78 -17 125 -26 114 -22 319 -85 460 -142 383 -152 719 -372
1005 -658 77 -77 154 -156 170 -175 279 -327 481 -692 604 -1095 35 -114 38
-127 54 -200 80 -370 97 -721 53 -1075 -17 -134 -22 -161 -67 -345 -61 -256
-197 -589 -314 -775 -14 -22 -25 -42 -25 -44 0 -3 -32 -53 -71 -113 -192 -289
-432 -544 -719 -760 -83 -62 -302 -203 -316 -203 -7 0 -14 -4 -16 -9 -8 -23
-356 -179 -493 -221 -22 -7 -49 -16 -60 -21 -48 -19 -228 -65 -345 -87 -210
-41 -288 -47 -580 -46 -279 0 -344 5 -558 43 -104 19 -355 86 -417 111 -11 5
-45 18 -75 29 -139 51 -398 173 -470 221 -14 10 -74 50 -135 90 -115 76 -202
141 -295 219 -59 50 -236 224 -286 281 -87 101 -143 170 -174 215 -19 27 -40
55 -47 62 -6 7 -47 68 -89 135 -90 141 -168 288 -214 403 -18 44 -43 103 -56
132 -13 28 -24 59 -24 68 0 8 -4 23 -10 33 -22 40 -96 328 -119 465 -40 237
-54 623 -30 802 35 264 44 310 90 490 115 450 376 923 699 1268 l65 69 50 -7
c124 -16 245 -70 289 -128 69 -92 104 -240 137 -572 8 -84 10 -240 6 -517 -7
-392 -7 -396 13 -391 11 3 20 9 20 13 0 17 156 160 210 192 19 12 37 25 40 29
11 14 180 89 238 105 73 20 66 -7 82 294 7 118 16 269 21 335 4 66 11 199 15
295 12 300 -4 289 455 297 374 7 478 -2 749 -66 158 -38 366 -126 503 -215 64
-41 180 -137 242 -201 120 -122 276 -351 366 -535 43 -90 110 -251 115 -282 4
-18 10 -37 14 -43 10 -12 52 -190 74 -305 61 -331 34 -776 -69 -1105 -6 -22
-18 -58 -24 -80 -66 -211 -198 -463 -340 -645 -75 -97 -244 -267 -311 -313
-25 -17 -47 -33 -50 -37 -13 -15 -113 -72 -180 -103 -165 -74 -287 -108 -475
-131 -220 -28 -268 -23 -214 21 217 175 330 355 379 599 13 63 9 223 -7 305
-12 62 -83 203 -137 275 -42 56 -92 103 -164 151 -49 34 -302 162 -437 222
-260 115 -539 250 -543 262 -2 5 -11 9 -19 9 -29 0 -139 125 -177 200 -33 67
-36 79 -36 161 0 178 85 294 270 369 103 42 212 56 328 42 226 -28 353 -108
474 -300 48 -76 188 -351 188 -370 0 -21 42 -103 62 -122 28 -26 52 -25 79 3
21 21 21 27 16 232 -7 251 -33 527 -54 573 -22 47 -51 42 -112 -19 -48 -47
-55 -51 -89 -46 -20 3 -77 24 -127 47 -49 23 -100 46 -112 51 -13 5 -33 13
-45 18 -105 44 -238 66 -388 66 -172 0 -291 -24 -400 -80 -91 -46 -104 -55
-183 -117 -217 -171 -316 -404 -287 -673 24 -227 138 -399 345 -522 57 -33
381 -190 465 -224 82 -33 282 -140 365 -194 124 -81 229 -189 280 -289 l40
-79 -1 -116 c0 -127 -11 -167 -65 -256 -67 -109 -222 -204 -375 -231 -88 -16
-120 -15 -239 5 -234 38 -468 256 -642 597 -29 55 -70 147 -93 204 -41 102
-71 150 -95 150 -15 0 -51 -40 -59 -66 -4 -11 -2 -213 4 -448 9 -371 12 -431
28 -464 12 -26 24 -38 42 -39 32 -4 46 8 99 80 23 32 54 62 67 67 27 10 109
-5 120 -21 3 -5 11 -9 19 -9 8 0 47 -15 87 -34 111 -52 189 -82 285 -109 193
-54 317 -68 630 -74 472 -8 977 63 1333 190 258 91 555 300 765 539 174 197
288 379 395 633 123 290 180 762 135 1120 -21 166 -21 167 -76 387 -15 63 -96
276 -109 288 -3 3 -23 41 -45 85 -38 78 -154 259 -192 300 -10 11 -35 41 -55
67 -98 121 -264 271 -408 369 -36 24 -67 46 -70 49 -14 14 -169 93 -305 156
-16 7 -70 27 -120 44 -49 17 -99 34 -110 39 -80 35 -394 98 -595 120 -268 30
-418 33 -888 21 -413 -11 -499 -11 -747 4 -324 19 -487 34 -498 45 -4 4 7 21
26 37 48 41 107 87 126 98 9 5 25 18 36 28 24 23 202 141 297 197 37 22 223
115 288 144 180 81 465 169 635 197 28 4 93 16 145 25 189 33 539 37 785 9z"/>
<path d="M9089 9660 c-66 -35 -109 -106 -109 -181 0 -33 28 -114 74 -211 45
-95 47 -144 10 -182 -21 -20 -37 -26 -71 -26 -55 0 -89 17 -136 69 -59 65 -71
156 -31 233 12 23 13 33 4 42 -8 8 -14 7 -23 -4 -7 -9 -38 -49 -70 -89 -57
-70 -67 -98 -26 -73 24 15 39 8 39 -20 0 -30 47 -107 89 -146 64 -59 128 -86
202 -87 57 0 70 4 103 29 103 78 107 178 14 373 -53 113 -58 150 -25 189 21
24 33 29 70 29 49 0 85 -21 121 -69 17 -22 21 -41 19 -99 0 -40 -3 -83 -5 -96
-6 -43 16 -32 67 34 27 36 53 65 57 65 5 0 8 5 8 11 0 7 -11 9 -28 6 -26 -5
-29 -2 -46 44 -47 134 -203 214 -307 159z"/>
<path d="M9645 9243 c19 -44 17 -64 -6 -90 -23 -24 -196 -156 -288 -218 -85
-58 -140 -158 -126 -228 6 -30 53 -136 65 -147 3 -3 20 -24 39 -48 50 -64 112
-103 176 -109 45 -5 63 -1 117 24 52 25 168 101 215 142 6 6 43 31 82 56 80
52 130 59 155 24 9 -13 21 -19 30 -16 18 7 16 10 -88 152 -77 104 -75 103 -86
86 -5 -10 -3 -22 6 -35 22 -32 17 -72 -15 -108 -38 -44 -296 -228 -341 -244
-70 -26 -123 -14 -178 39 -45 43 -72 98 -72 147 0 76 31 112 208 240 157 114
183 130 217 130 21 0 40 -9 55 -25 21 -22 40 -25 40 -6 0 5 -12 24 -27 43 -16
19 -36 46 -45 59 -10 13 -36 48 -58 78 -22 30 -40 58 -40 63 0 4 -9 8 -21 8
-16 0 -19 -4 -14 -17z"/>
<path d="M2755 8451 c-72 -24 -117 -54 -172 -115 -47 -51 -61 -75 -121 -206
-80 -174 -102 -224 -102 -231 0 -15 30 -10 36 6 18 46 59 40 216 -34 48 -23
90 -41 92 -41 15 0 254 -120 265 -133 9 -12 12 -30 7 -57 -5 -30 -3 -40 7 -40
17 0 182 357 203 441 49 193 -73 382 -270 418 -77 14 -101 13 -161 -8z m116
-129 c108 -30 198 -94 236 -167 26 -48 21 -150 -9 -210 -35 -68 -61 -90 -106
-88 -31 1 -192 67 -317 129 -16 8 -63 29 -102 46 -56 23 -73 34 -73 50 0 27
75 170 104 197 32 30 112 60 161 61 22 0 70 -8 106 -18z"/>
<path d="M10194 8419 c8 -43 -19 -65 -166 -129 -400 -175 -381 -170 -422 -118
-17 22 -29 29 -35 23 -10 -10 94 -262 161 -390 53 -102 126 -159 204 -159 114
0 191 114 160 235 -14 53 -15 53 51 3 53 -41 91 -49 134 -31 82 35 113 111 90
218 -17 77 -151 379 -169 379 -11 0 -13 -8 -8 -31z m92 -255 c32 -89 28 -111
-27 -156 -77 -63 -158 -41 -211 57 -34 61 -35 83 -5 90 12 4 58 24 102 45 44
21 87 39 96 39 12 1 25 -21 45 -75z m-282 -136 c35 -103 31 -145 -18 -194 -29
-29 -87 -54 -126 -54 -56 0 -130 103 -130 182 0 30 6 42 29 59 34 25 192 98
206 95 6 -2 23 -41 39 -88z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
